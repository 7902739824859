import { useStore } from '@/store'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { rts as RT } from '@/router'
import { message } from 'antd'
const NavigationBar = () => {
  const exitIcon = require('@/assets/images/exit.png')
  const location = useLocation()
  const navigate = useNavigate()
  const [path, setPath] = useState()
  // useEffect(() => {
  //   setPathName(location.pathname)
  // }, [location.pathname])

  const { navigationStore: NS } = useStore()
  const exitNavigate = (value, e) => {
    e.stopPropagation()
    if (NS.navigateList.length > 1) {
      const val = value
      val.path = `/${val.path}`
      // console.log('val', NS.navigateList.indexOf(val) - 1)
      const index = NS.navigateList.indexOf(val) - 1
      const navTo = NS.navigateList[index > 0 ? index : 0].path
      console.log('navTo', navTo)
      if (path?.length) {
        var to = path?.filter(function (value) {
          if (value.split('?')[0] == `/${navTo}`) {
            return value;
          }
        });
        if (to.length) {
          val.path === location.pathname && navigate(to[0])
        } else {
          val.path === location.pathname && navigate(navTo)
        }
      } else {
        val.path === location.pathname && navigate(navTo)
      }


      NS.delList(val)
      NS.refreshView()

      return
    }
    message.warning('至少保留一个标签 !')

  }

  const TAPnavbar = (val) => {
    if (path?.length) {
      var to = path.filter(function (value) {
        if (value.split('?')[0] == `/${val.path}`) {
          return value;
        }
      });
      console.log(to)
      if (to.length) {
        navigate(to[0])
      } else {
        navigate(`/${val.path}`)
      }
    } else {
      navigate(`/${val.path}`)
    }
    const ATLP = val.path.split('/')

    NS.delNavigateChildrenList(true)
    if (ATLP.length > 2) {
      const SP = `${ATLP[0]}/${ATLP[1]}`
      const topTitle = RT[0].children.filter(i => i.path === SP)[0].title
      NS.addNavigateChildrenList(topTitle)
      NS.addNavigateChildrenList(val.title)
      return
    }
    NS.addNavigateChildrenList(val.title)
  }

  const [title, setTitle] = useState('')
  const OMover = (val) => {
    setTitle(val)
  }
  // OMout
  const OMout = (val) => {
    setTitle('')
  }

  const setNavTitle = () => {
    const LP = location.pathname.substring(1)
    const AllRT = RT[0].children
    const ATRT = AllRT.filter(i => i.path === LP)[0]
    if (ATRT) {
      // delete ATRT.element
      // ATRT.path = `/${ATRT.path}`
      // console.log('ATRT', ATRT)
      NS.addList(ATRT)
    }
    console.log('AllRT', ATRT, NS.navigateList, window.location)
    if (window.location.search) {
      let newData = path?.length ? [...path] : []
      newData.push(`${window.location.pathname}${window.location.search}`)
      let s = new Set(newData);
      let test = Array.from(s)
      console.log('页面有参数------', test)
      setPath(test)
    }

  }
  useEffect(() => {
    console.log('头部导航：', NS.navigateList)
    setNavTitle()
    // NS.refreshView(true)//不要解开这个注释
    return () => {
      // console.log('navi 组件销毁了',)
      NS.refreshView()
    }

  }, [location.pathname, NS.navigateList])

  return (
    <div className='navHeader'>
      {/* <ul className="navigateBarBox">
        <li className={`navigateBar`} onClick={() => { navigate('/') }}>
          <svg t="1653205278088" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1405" width="16" height="16"><path d="M597.56544 102.21568l353.0752 339.29216A40.96 40.96 0 0 1 922.25536 512H860.16v327.68a122.88 122.88 0 0 1-122.88 122.88H286.72a122.88 122.88 0 0 1-122.88-122.88V512H101.888a40.96 40.96 0 0 1-28.3648-70.51264l353.83296-339.3536a122.88 122.88 0 0 1 170.1888 0.08192z" fill="#131415" p-id="1406"></path><path d="M554.98752 146.51392a61.44 61.44 0 0 0-82.18624-2.6624l-2.90816 2.62144L152.7808 450.56H225.28v389.12a61.44 61.44 0 0 0 57.83552 61.3376L286.72 901.12h450.56a61.44 61.44 0 0 0 61.3376-57.83552L798.72 839.68V450.56h72.66304L554.98752 146.51392z" fill="#FFFFFF" p-id="1407"></path><path d="M368.64 696.32m30.72 0l225.28 0q30.72 0 30.72 30.72l0 0q0 30.72-30.72 30.72l-225.28 0q-30.72 0-30.72-30.72l0 0q0-30.72 30.72-30.72Z" fill="#131415" p-id="1408"></path></svg>
        </li>
        {
          NS.navigateList?.map((item, index) => (
            <li key={index}
              className={`navigateBar ${item.path === location.pathname.substring(1) && 'navigateBarActive'}`}
              onClick={(e) => TAPnavbar(item, e)}
            // onMouseOver={(e) => OMover(item.title, e)}
            // onMouseOut={(e) => OMout(item.title, e)}
            >
              <span className='txt'>{item.title}</span>

              <img className='exitIcon' src={exitIcon} alt=""
                onClick={(e) => exitNavigate(item, e)}
              />

              <span className='title' style={item.title !== title ? { 'visibility': 'hidden' } : { 'visibility': 'visible' }}>
                {title && title}
                <span className='smallTitle'>{window.location.host}</span>
              </span>
            </li>
          ))
        }
      </ul> */}
    </div>
  )
}

export default observer(NavigationBar)
