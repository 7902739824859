import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from "react-router-dom";

// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import moment from 'moment';
import 'moment/locale/zh-cn';

// import '@/utils/forbidden'

import { config as AmapReactConfig } from '@amap/amap-react';
moment.locale('zh-cn');

AmapReactConfig.version = '2.0'; // 默认2.0，这里可以不修改
AmapReactConfig.key = '2c8d9e41309b884adea07537f3db2cff';
AmapReactConfig.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <Router>
        <App />
      </Router>
    </ConfigProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);
/* 以下是react18配置  因18版本无法使用antd部分组件  版本又降回17 */
// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
//     <Router>
//       <App/>
//     </Router>
// );
reportWebVitals();