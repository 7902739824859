
// // const onRouteBefore = ({ pathname, meta }) => {
// //   // 动态修改页面title
// //   if (meta.title !== undefined) {
// //     document.title = meta.title
// //   }
// //   // 判断未登录跳转登录页
// //   // if (meta.needLogin) {
// //   //   if (!isLogin) {
// //   //     return '/login'
// //   //   }
// //   // }
// // }

// export default routes
import Layouts from '../layout'
import Login from '../pages/Login/Login'
import { lazy, Suspense } from 'react'
import { Spin } from 'antd'

// const DataOverview = lazy(() => import('@/pages/OverView/DataOverview'))
// const EventStatistics = lazy(() => import('@/pages/OverView/EventStatistics'))
// const OrdersReceivedStatistics = lazy(() => import('@/pages/OverView/OrdersReceivedStatistics'))
const About = lazy(() => import('@/pages/About/About'))
// const Login = lazy(() => import('@/pages/Login/Login'))
const Notfond = lazy(() => import('@/pages/Error/NotFond'))

const PublishEvent = lazy(() => import('@/pages/Competition/PublishEvent'))
const ListOfEvents = lazy(() => import('@/pages/Competition/ListOfEvents'))
const RegistrationManagement = lazy(() => import('@/pages/Competition/RegistrationManagement'))
const OrderDetail = lazy(() => import('@/pages/Competition/OrderDetail'))

const RegistrationReview = lazy(() => import('@/pages/Competition/RegistrationReview'))
const ApplicantInformationDetails = lazy(() => import('@/pages/Competition/ApplicantInformationDetails'))
// const PerformanceManagement = lazy(() => import('@/pages/Competition/PerformanceManagement'))
// const PerformanceManagementEdit = lazy(() => import('@/pages/Competition/PerformanceManagementEdit'))
// const ApplicantInformationManagement = lazy(() => import('@/pages/Competition/ApplicantInformationManagement'))
// const EventService = lazy(() => import('@/pages/Competition/EventService'))
// const TermsNotice = lazy(() => import('@/pages/Competition/TermsNotice'))
// const TermsAgreement = lazy(() => import('@/pages/Competition/TermsAgreement'))
// const BlacklistManagement = lazy(() => import('@/pages/Competition/BlacklistManagement'))
// const TaglistManagement = lazy(() => import('@/pages/Competition/TaglistManagement'))
// const PreviewGroupManagement = lazy(() => import('@/pages/Competition/PreviewGroupManagement'))
// const InvitationCodeManagement = lazy(() => import('@/pages/Competition/InvitationCodeManagement'))
// const AccountManagement = lazy(() => import('@/pages/UserControl/AccountManagement'))
// const CenterManager = lazy(() => import('@/pages/UserControl/CenterManager'))
// const BackgroundAdministrator = lazy(() => import('@/pages/UserControl/BackgroundAdministrator'))
// const CancellationApplication = lazy(() => import('@/pages/UserControl/CancellationApplication'))
// const AccountDetails = lazy(() => import('@/pages/UserControl/AccountDetails'))
// const CMAccountDetails = lazy(() => import('@/pages/UserControl/CMAccountDetails'))
// const SortManagement = lazy(() => import('@/pages/Journalism/SortManagement'))
// const ContentManagement = lazy(() => import('@/pages/Journalism/ContentManagement'))
// const PublishContent = lazy(() => import('@/pages/Journalism/PublishContent'))

// const MiddlegroundEventAudit = lazy(() => import('@/pages/Audit/MiddlegroundEventAudit'))
// const DetailsOfMidStageEvents = lazy(() => import('@/pages/Audit/DetailsOfMidStageEvents'))
// const CommentReview = lazy(() => import('@/pages/Audit/CommentReview'))
// const RefundAudit = lazy(() => import('@/pages/Audit/RefundAudit'))
// const DetailsRefundAudit = lazy(() => import('@/pages/Audit/DetailsRefundAudit'))
// const PostersManagement = lazy(() => import('@/pages/System/PostersManagement'))
// const EditThePoster = lazy(() => import('@/pages/System/EditThePoster'))
// const PushTheManagement = lazy(() => import('@/pages/System/PushTheManagement'))
// const Feedback = lazy(() => import('@/pages/System/Feedback'))
// const ReportManagement = lazy(() => import('@/pages/System/ReportManagement'))
// const MaterialCenter = lazy(() => import('@/pages/System/MaterialCenter'))
// const SMSManagement = lazy(() => import('@/pages/System/SMSManagement'))
// const Broadcast = lazy(() => import('@/pages/System/Broadcast'))
// const Advertising = lazy(() => import('@/pages/System/Advertising'))
// const EditAdvertising = lazy(() => import('@/pages/System/EditAdvertising'))
// const QA = lazy(() => import('@/pages/System/Qa'))
// const EditQa = lazy(() => import('@/pages/System/EditQa'))
// const Wallet = lazy(() => import('@/pages/System/Wallet'))
// const WalletRunning = lazy(() => import('@/pages/System/WalletRunning'))
// const WalletDetail = lazy(() => import('@/pages/System/WalletDetail'))
// const Order = lazy(() => import('@/pages/System/Order'))
// const Gold = lazy(() => import('@/pages/System/Gold'))
// const CloseGold = lazy(() => import('@/pages/System/CloseGold'))
// const DetailGold = lazy(() => import('@/pages/System/DetailGold'))
// const MenuManagement = lazy(() => import('@/pages/Jurisdiction/MenuManagement'))
// const BackAccount = lazy(() => import('@/pages/Jurisdiction/BackAccount'))
// const LogManagement = lazy(() => import('@/pages/System/LogManagement'))
// const DictionaryManagement = lazy(() => import('@/pages/System/DictionaryManagement'))
// const SettlementRecords = lazy(() => import('@/pages/AccountChecking/SettlementRecords'))
// const BillingDetails = lazy(() => import('@/pages/AccountChecking/BillingDetails'))



// const EditSession = lazy(() => import('@/pages/Competition/EditSession'))

const lazyLoad = (children) => {
  return (
    <Suspense fallback={<Spin style={{ 'position': 'absolute', 'top': '50%', 'left': '50%', 'transform': 'translate(-50%, -50%)', 'zIndex': '99' }} tip='Lodding...' />}>
      {children}
    </Suspense>
  )
}
export const rts = [
  {
    path: '/',
    authen: true,
    element: <Layouts />,
    title: '首页',
    children: [
      // {
      //   index: true,
      //   element: lazyLoad(<DataOverview />)
      // },
      // {
      //   title: '数据总览',
      //   path: 'overview/dataOverview',
      //   element: lazyLoad(<DataOverview />)
      // },
      // {
      //   title: '赛事统计',
      //   path: 'overview/eventStatistics',
      //   element: lazyLoad(<EventStatistics />)
      // },
      // {
      //   title: '订单统计',
      //   path: 'overview/ordersReceivedStatistics',
      //   element: lazyLoad(<OrdersReceivedStatistics />)
      // },
      // {
      //   title: '赛事xxx',
      //   path: 'competition/publishEvent/editSession',
      //   element: lazyLoad(<EditSession />)
      // },
      // {
      //   title: '赛事列表',
      //   path: 'competition/listOfEvents',
      //   element: lazyLoad(<ListOfEvents />)
      // },
      // {
      //   title: '赛事发布',
      //   path: 'competition/listOfEvents/publishEvent',
      //   element: lazyLoad(<PublishEvent />),
      // },
      {
        title: '赛事列表',
        path: 'competition/listOfEvents',
        element: lazyLoad(<ListOfEvents />)
      },
      {
        title: '赛事发布',
        path: 'competition/listOfEvents/publishEvent',
        element: lazyLoad(<PublishEvent />),
      },
      {
        title: '报名订单管理',
        path: 'competition/registrationManagement',
        element: lazyLoad(<RegistrationManagement />)
      },
      {
        title: '订单详情',
        path: 'competition/registrationManagement/orderDetail',
        element: lazyLoad(<OrderDetail />)
      },
      {
        title: '报名审核',
        path: 'competition/registrationReview',
        element: lazyLoad(<RegistrationReview />)
      },
      {
        title: '报名人信息详情',
        path: 'competition/registrationReview/applicantInformationDetails',
        element: lazyLoad(<ApplicantInformationDetails />)
      },

    ]
  },
  {
    path: '/login',
    element: <Login />,
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '*',
    element: <Notfond />,
    title: '404 NotFond'
  }
]

// export { rts, publicRoute }