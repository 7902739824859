import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '@/store';
import { Breadcrumb } from 'antd';
import { rts as RT } from '@/router'

const NavigattionTitle = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { navigationStore: NS } = useStore()
  const [name, setName] = useState()
  // const [childrenName,setChilrenName] = useState()

  const [pathA] = RT

  const local = location.pathname.substring(1).split('/')


  const firstLocal = `${local[0]}/${local[1]}`
  const lastLocal = `${local[0]}/${local[1]}/${local[2]}`
  // console.log('location.pathname',firstLocal,lastLocal)
  const first = pathA.children.find(i => i.path === firstLocal)
  const last = pathA.children.find(i => i.path === lastLocal)

  useEffect(() => {
    // const navTitle = NS.navigateList.find(i => i.path === location.pathname.substring(1))
    // // console.log('navTitle', navTitle, 'location.pathname',location.pathname)
    // // console.log('navTitle', navTitle)
    // setName(navTitle ? navTitle.title : NS.navTitle)

    // if (NS.navigateChildrenList.length) {
    //   setName(NS.navigateChildrenList)
    // }
    // console.log('nsname', NS.navigateChildrenList)

    // console.log('一级',first, '二级',last )
    return () => {
      // NS.delNavigateChildrenList()
      // console.log('title组件销毁了', )
    }
  }, [location.pathname, NS.navigateList, NS.navTitle])

  return (
    <div className="navigationTitle">
      {/* <span className="navigationTitleIcon"></span> */}
      <Breadcrumb>
        {first && (<Breadcrumb.Item className="navigationTitleTxt"
          onClick={() => { navigate(`/${firstLocal}`) }}>
          <a href=''>{first.title}</a>
        </Breadcrumb.Item>)}


        {last && <Breadcrumb.Item className="navigationTitleTxt">{last.title}</Breadcrumb.Item>}

        {/* <Breadcrumb.Item className="navigationTitleTxt">{name ? name[0] : ''}</Breadcrumb.Item> */}
        {/* {
          NS.navigateChildrenList.length ?
            NS.navigateChildrenList.map((item, index) => (
              <Breadcrumb.Item key={index} className="navigationTitleTxt">{item}</Breadcrumb.Item>
            )) : null
        } */}
      </Breadcrumb>
      {/* <span className="navigationTitleTxt">{ name }</span> */}
    </div>
  );
}

export default observer(NavigattionTitle);
// export default NavigattionTitle;