import { makeAutoObservable } from "mobx";

class Global {
  globalData = {
    style: 1,
  }

  constructor() {
    makeAutoObservable(this)
  }

  // 切换MainBox 背景样式
  cutStyle = (val) => {
    this.globalData.style = val
  }

}
export { Global }