import { $api } from '@/config/api'
import { useStore } from '@/store'
import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import { iconList } from './asideRouteList'
const Asides = () => {
  const { navigationStore: NS, routeStore: RS } = useStore()
  const RTLIST = useRef(RS.rtList.filter(i => i.isShow))

  const [menu, setMenu] = useState([])
  const [count, setCount] = useState(0)//一级路由icon默认索引
  const [pathName, setPathName] = useState('')//一级路由切换path

  const [routeCount, setRouteCount] = useState(0)//二级路由icon默认索引
  const [routeName, setRouteName] = useState('')//二级路由切换列表path

  const [checkIndex, setCheckIndex] = useState(0)

  const [warnCount, setWarnCont] = useState([
    {
      path: '/account-checking',
      count: 2
    },
    {
      path: '/information',
      count: 200
    },
    {
      path: '/overview',
      count: 33
    }
  ])

  const navigate = useNavigate()//跳转hook
  // 点击一级路由icon
  // const clickTab = (index, item) => {
  //   console.log(item, index)
  //   setPathName(item.href)//修改一级路由保存的path信息
  //   setRouteCount(index)//修改二级路由索引  二级路由通过一级路由的索引渲染出对应的二级路由
  //   setWarnCont(
  //     [...warnCount],
  //     warnCount[1].count = 3
  //   )
  // }
  // 点击二级列表
  const clickRout = (routeIndex, routeItem) => {
    setRouteName('/competition/listOfEvents')//修改保存的二级路由path
    navigate('/competition/listOfEvents')//跳转
    document.title = '赛事列表'
  }
  // 点击二级列表
  const clickRoute = (routeIndex, routeItem) => {
    setRouteName('/competition/registrationManagement')//修改保存的二级路由path
    navigate('/competition/registrationManagement')//跳转
    document.title = '报名订单管理'
    setCheckIndex(1)

  }

  const clickRoutes = (routeIndex, routeItem) => {
    setRouteName('/competition/registrationReview')//修改保存的二级路由path
    navigate('/competition/registrationReview')//跳转
    document.title = '报名审核'
    setCheckIndex(2)
  }

  const location = useLocation()//获取路由信息hook

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (location.pathname === '/') {
        // navigate(res.data[0].children[0].href)
        navigate('/competition/listOfEvents')
        return
      }
      setPathName('/competition/listOfEvents')
      setRouteName('/competition/listOfEvents')
    } else {
      //message.error('请重新登录')
      navigate('/login')

    }


  }, [location.pathname, navigate])

  return (
    <>
      {/* <div className="asideContentLeft">
        <ul>
          {
            menu.map((item, index) => (
              <li key={item.id} className={`asideBtns ${item.href === pathName ? 'asideBtnsActive' : null}`}
                onClick={(e) => clickTab(index, item, e)}
              >
                {item.icon
                  ? <img src={item.icon} alt='' />
                  : ''
                }
                <span className='asideBtnsTxt'>{item.title}</span>
              </li>
            ))
          }
        </ul>
      </div> */}

      <div className="asideContentRight">
        <ul>
          <li className={window.location.pathname == '/competition/listOfEvents' ? 'routeName routeNameActive' : 'routeName'}
            onClick={(e) => clickRout()}
          >
            <span></span>
            赛事列表
          </li>
          <li className={window.location.pathname == '/competition/registrationManagement' ? 'routeName routeNameActive' : 'routeName'}
            onClick={(e) => clickRoute()}
          >
            <span></span>
            报名订单管理
          </li>
          <li className={window.location.pathname == '/competition/registrationReview' ? 'routeName routeNameActive' : 'routeName'}
            onClick={(e) => clickRoutes()}
          >
            <span></span>
            报名审核
          </li>
        </ul>
      </div>
    </>
  );
}

export default observer(Asides);