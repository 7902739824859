import './App.less'
import '@/assets/styles/coverAntd.less'
// import Layouts from './layout/index'
import { useRoutes } from 'react-router-dom'
import { rts } from '@/router'
import { useEffect } from 'react'

const App = () => {

  const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
  // const isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  
  let browser = {
      versions: function () {
          const u = navigator.userAgent;
          return {
              trident: u.indexOf('Trident') > -1, //IE内核
          };
      }()
  }
  if (browser.versions.trident && !isIE11) {
    alert('浏览器版本过低')
  }
  return (
    // <Layouts>
    //   {children}
    // </Layouts>
    useRoutes(rts)
  );
}

export default App;